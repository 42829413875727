import { Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ThemeService{

  private name = new BehaviorSubject('');

  constructor() {}

  public getTheme() {
   return this.name.asObservable();
  }

  public setTheme = (name) => this.name.next(name);
}
