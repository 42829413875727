export const themes = {
  dark_psb : {
      // color
    '--tab-bg': '#464349',
    '--tab-item': '#323139',
    '--text': '#eae4e4',
    '--card-dark-light': '#353235',
    '--card-dark-light-2': '#26252c',
    '--card-dark-light-no-message': 'rgba(38, 37, 44, .8)',
    '--success': '#00c7c7',
    '--primary': '#005e89',
    '--warn': '#ffd475',
    '--danger': '#c86a88',
    '--color':'#fff',
    '--color-header':'#fff',
    // --color-50: '#aeaeae'
    '--color-50': '#e7e7e7',
    '--orange-gray-50':'#C8A46A',
    '--light-gray':'rgb(45,44,51)',
    '--black-white': '#fff',
    '--black-white-rgba':'rgba(250,250,250,1)',
    '--main-color': '#fff',
    '--menu-color': '#fafafa',
    '--light-gray-white-color': '#fff',
    '--orange-white': '#C8A46A',
    '--orange-dark-blue': '#C8A46A',
    '--orange-black-50': '#C8A46A',
    '--radio-btn-color': '#C8A46A',
    '--orange-blue': '#C8A46A',
    '--white-gold': '#C8A46A',
    '--gray-gold': '#C8A46A',
    '--orange-red': '#C8A46A',
    '--tree-item-active': '#464349',
    '--bg-dark-light':'rgb(45,44,51)',
    '--bg-dark-light-2':'rgb(45,44,51)',
    '--bg-dark-light-3':'rgb(45,44,51)',
    // backColor
    '--card-background':'linear-gradient(111.09deg, #4C4951 0%, #353235 100%)',
    '--card-background-dark':'linear-gradient(111.09deg, #4C4951 80%, #353235 100%)',
    '--blue-dark': 'linear-gradient(111.09deg, #4C4951 0%, #353235 100%)',
    '--loader-bg':'#464349',
    '--toast-bg':'rgb(45,44,51)',
    '--system-toast-bg':'rgb(0 0 0 / 24%)',
    '--system-toast-avatar-bg':'rgb(45,44,51)',
    '--form-auth-bg':'linear-gradient(107.28deg, #4C4951 0%, #353235 100%)',
    '--form-auth-header':'linear-gradient(107.28deg, #4C4951 0%, #353235 100%)',
    '--btn-bg-color': 'linear-gradient(124.7deg, #4C4951 0%, #353235 100%)',
    '--background':'#1C1B20',
    '--background-color': '#26252C',
    '--dash-bg':'none',
    '--gridster-bg':'#444',
    '--gridster-dash-bg':'none',
    '--common-select-option-bg': 'rgb(130,130,130)',
    '--charts-types-select-bg': '#30303D',
    '--scrollbar-track-bg': 'rgba(255, 255, 255, .1)',
    '--scrollbar-track-main-bg': 'rgba(255, 255, 255, .4)',
    '--tree-bg': 'rgba(255, 255, 255, .4)',
    '--scrollbar-thumb-main-bg': 'rgba(255, 255, 255, .3)',
    '--scrollbar-thumb-bg': 'rgba(255, 255, 255, .5)',
    '--scrollbar-thumb-bg-select': '#999',
    // hover
    '--hover-color': '#CEBE77',
    '--hover-color-1': '#d3b34b',
    '--primary-color': '#CEBE77',
    '--row-hover-color': 'rgba(255, 255, 255, .1)',
    '--hover-color-2': 'rgba(206, 190, 119, 0.8)',
    '--hover-color-3': 'rgba(206, 190, 119, 0.2)',
    '--hover-color-dlgram': 'rgba(206, 190, 119, 10%)',
    '--dlgram-active-chat-bg-color': 'rgba(206, 190, 119, 75%)',
    '--dlgram-active-chat-folder-bg': 'rgba(206, 190, 119, 0.15)',
    // button
    '--btn-color': '#fff',
    '--btn-dg-cancel-color': '#fff',
    '--btn-dg-cancel-bg-color': 'red',
    '--btn-dg-cancel-br-color': 'red',
    '--btn-dg-exec-bg-color': '#C8A46A',
    '--btn-dg-exec-br-color': '#C8A46A',
    '--btn-dg-exec-color': '#fff',
    '--card-list-bottom-border': '1px dashed #fff',
    '--btn-disabled-bg-color': 'rgba(150, 150, 150, .1)',
    '--btn-disabled-border-color': 'rgba(255, 255, 255, .5)',
    '--btn-disabled-color': 'rgba(255, 255, 255, .5)',
    //table
    '--table-row-active': '#495367',
    '--th-bg-color': 'rgb(56,56,60)',
    '--grid-focused-row-bg':'rgb(73,83,103)',
    '--th-bor': 'none',
    '--td-bor': 'rgb(63,63,69)',
    // '--td-bor': '1px solid rgb(63,63,69)',
    '--grid-td-border-color': 'rgb(63,63,69)',
    // shadow
    '--bx-shadow': '0 3px 1px -2px rgba(255,255,255,.2), 0 2px 2px 0 rgba(255,255,255,.14), 0 1px 5px 0 rgba(255,255,255,.12)',
    '--dash-bx-shadow': 'rgba(255, 255, 255, 0.3)',
    '--bi-hd-bottom-bor': '2px solid #fff',
    // images
    '--logo-width':'206px',
    '--logo-height':'31px',
    '--logo-file':'url(\'./assets/img/logo/psb/main-logo-dark.svg\')',
    '--logo-file2':'url(\'./assets/img/logo/psb/main-logo-dark.svg\')',
    '--logo-file-2':'url(\'./assets/img/logo/psb/main-logo-dark-2-gray.png\')',
    '--back-img': 'url(\'assets/img/back-dark.svg\')',
    '--auth-title-img': 'url(\'assets/img/logo/psb/logo-text-dark.svg\')',
    '--navbar-logo':'url(\'./assets/img/logo/psb/logo-dark.svg\')',
    '--navbar-logo2':'url(\'./assets/img/logo/psb/logo-dark.svg\')',
    '--navbar-logo-mini':'url(\'./assets/img/logo/psb/sqb-mini-dark.png\')',
    '--theme-sun-icon':'url(\'./assets/img/sun.svg\')',
    '--theme-sun-auth-icon':'url(\'./assets/img/sun.svg\')',
    '--theme-moon-icon':'url(\'./assets/img/moon-dark.svg\')',
    '--pie': 'url(\'./assets/img/charts/pie-dark.png\')',
    '--pie3D': 'url(\'./assets/img/charts/pie3D-dark.png\')',
    '--donut': 'url(\'./assets/img/charts/donut-dark.png\')',
    '--donut3D': 'url(\'./assets/img/charts/donut3D-dark.png\')',
    '--semi-circle': 'url(\'./assets/img/charts/semi-circle-dark.png\')',
    '--radius': 'url(\'./assets/img/charts/radius-dark.png\')',
    '--line': 'url(\'./assets/img/charts/line-dark.png\')',
    '--column': 'url(\'./assets/img/charts/column-dark.png\')',
    '--bar': 'url(\'./assets/img/charts/bar-dark.png\')',
    '--favicon': 'url(\'./assets/img/logo/psb/favicon.ico\')',
    // others
    '--toggle-ps-left':'-1px',
    '--toggle-nav-ps-left':'-4px',
    '--dash-header-bor':'1px solid #3C3B41',
    '--mat-label-bor-bottom':' 1px solid #3C3B41',
    '--treemap-td-bor-color': '#fff',
    '--treemap-bg-color': 'linear-gradient(111.09deg, #4C4951 0%, #353235 100%)',
    '--treemap-height': 'calc(100% - 27px)',
    '--progress-default': '#C8A46A',
    '--warning-bg': '#fff9df',
    '--warning-text':'rgba(0, 0, 0, 0.62)',
    '--toggle-btn-fg': '#C8A46A',
    '--toggle-btn-bg': '#d1b17d',
    '--d-icon-menu-color': 'rgba(206, 190, 119, 0.8)',
    '--mat-datepicker-actions-btn-cancel': 'rgba(255, 255, 255, .8)',
    '--mat-datepicker-actions-btn-select-bg': 'rgba(150, 150, 150, 0.1)',
    '--dl-file-message-file-size-color': '#858585',
  },

  light_psb: {
    // color
    '--tab-bg': '#f3f4f6',
    '--tab-item': '#fff',
    '--text': '#676767',
    '--card-dark-light': '#fff',
    '--card-dark-light-2': '#fff',
    '--card-dark-light-no-message': 'rgba(255, 255, 255, .6)',
    '--success': '#00c7c7',
    '--primary': '#005e89',
    '--warn': '#ffd5c0',
    '--bg-dark-light':'#d1d1d1',
    '--bg-dark-light-2':'rgb(229 235 239)',
    '--bg-dark-light-3':'rgb(255 255 255)',
    '--danger': '#fc9a5c',
    '--color':'rgb(112,112,112)',
    '--color-header':'rgb(112,112,112)',
    '--color-50': 'rgb(133,133,133)',
    '--orange-gray-50':'rgb(112,112,112)',
    '--black-white':'#222',
    '--black-white-rgba':'rgba(0,0,0,1)',
    '--light-gray':'#f3f3f3',
    '--main-color': '#005e89',
    '--menu-color': '#b2b2b2',
    '--light-gray-white-color': '#CACACA',
    '--orange-white': '#fff',
    '--orange-dark-blue': '#005e89',
    '--orange-black-50': '#555555',
    '--radio-btn-color': '#E91E63',
    '--orange-blue': '#3F51B5',
    '--orange-red': 'red',
    '--white-gold': '#fff',
    '--gray-gold': '#cac6c6',
    '--tree-item-active': 'rgb(45,44,51)',
    // backColor
    '--card-background':'#fafafa',
    '--card-background-dark':'#fafafa',
    '--blue-dark': '#0e7bbf',
    '--toast-bg':'rgba(0, 62, 100, 16%)',
    '--system-toast-bg':'rgba(45,44,51, 0.24)',
    '--system-toast-avatar-bg':'rgb(0 0 0 / 24%)',
    '--loader-bg':'#fafafa',
    '--form-auth-bg':'linear-gradient(312.16deg, #005e89 8.63%, #001827 97.1%)',
    '--form-auth-header':'linear-gradient(312.16deg, #005e89 8.63%, #001827 97.1%)',
    '--background':'#fafafa',
    '--background-color':'#FBFBFB',
    '--dash-bg':'#fff',
    '--gridster-bg':'#61A9CF',
    '--gridster-dash-bg':'#fff',
    '--charts-types-select-bg': '#fff',
    '--scrollbar-track-bg': 'rgba(150, 150, 150, .1)',
    '--scrollbar-track-main-bg': 'rgba(100, 100, 100, .2)',
    '--tree-bg': 'rgba(255, 255, 255, .9)',
    '--scrollbar-thumb-main-bg': 'rgba(150, 150, 150, .3)',
    '--scrollbar-thumb-bg': 'rgba(200, 200, 200, .5)',
    '--scrollbar-thumb-bg-select': 'rgba(200, 200, 200, .5)',
    // hover
    '--hover-color':'#005e89',
    '--hover-color-1':'rgba(0,62,100,0.58)',
    '--primary-color': '#0e6398',
    '--row-hover-color': 'rgba(150, 150, 150, .1)',
    '--hover-color-2': 'rgba(0, 62, 100, 0.2)',
    '--hover-color-3': 'rgba(0, 62, 100, 0.2)',
    '--hover-color-dlgram': 'rgba(0, 62, 100, 16%)',
    '--dlgram-active-chat-bg-color': 'rgba(0, 62, 100, 75%)',
    '--dlgram-active-chat-folder-bg': 'rgba(0, 62, 100, 0.15)',
    // button
    '--btn-bg-color':'#d1d1d1',
    '--btn-color':'#fff',
    '--btn-dg-cancel-color':'red',
    '--btn-dg-cancel-br-color':'red',
    '--btn-dg-cancel-bg-color':'#fff',
    '--btn-dg-exec-bg-color':'#fff',
    '--btn-dg-exec-color':'#005e89',
    '--btn-dg-exec-br-color':'#005e89',
    '--logo-width':'206px',
    '--logo-height':'31px',
    '--logo-file':'url(\'./assets/img/logo/psb/main-logo.svg\')',
    '--logo-file2':'url(\'./assets/img/logo/psb/bg-logo-smoke.svg\')',
    '--back-img':'url(\'assets/img/back.svg\')',
    '--auth-title-img': 'url(\'assets/img/logo/psb/logo-text.svg\')',
    '--card-list-bottom-border':'1px dashed #d1d1d1',
    '--btn-disabled-bg-color': 'rgba(255, 255, 255, .4)',
    '--btn-disabled-border-color': '#00000042',
    '--btn-disabled-color': '#00000042',
    // table
    '--table-row-active': '#d1d1d1',
    '--th-bg-color':'#e8e8e8',
    '--grid-focused-row-bg':'#d1d1d1',
    '--th-bor':'none',
    '--td-bor':'rgb(222,226,230)',
    '--grid-td-border-color': 'rgba(0,0,0,.12)',
    '--bi-hd-bottom-bor':'2px solid red',
    // box shadow
    '--bx-shadow':'0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12)',
    '--dash-bx-shadow': 'rgba(0, 0, 0, 0.3)',
    // images
    '--logo-file-2':'url(\'./assets/img/logo/psb/main-logo-light-2.svg\')',
    '--navbar-logo':'url(\'./assets/img/logo/psb/logo.svg\')',
    '--navbar-logo2':'url(\'./assets/img/logo/psb/logo-dark.svg\')',
    '--navbar-logo-mini':'url(\'./assets/img/logo/psb/sqb-mini.png\')',
    '--theme-sun-icon':'url(\'./assets/img/sun-day.svg\')',
    '--theme-sun-auth-icon':'url(\'./assets/img/sun.svg\')',
    '--theme-moon-icon':'url(\'./assets/img/moon-nav.svg\')',
    '--pie': 'url(\'./assets/img/charts/pie.png\')',
    '--pie3D': 'url(\'./assets/img/charts/pie3D.png\')',
    '--donut': 'url(\'./assets/img/charts/donut.png\')',
    '--donut3D': 'url(\'./assets/img/charts/donut3D.png\')',
    '--semi-circle': 'url(\'./assets/img/charts/semi-circle.png\')',
    '--radius': 'url(\'./assets/img/charts/radius.png\')',
    '--line': 'url(\'./assets/img/charts/line.png\')',
    '--column': 'url(\'./assets/img/charts/column.png\')',
    '--bar': 'url(\'./assets/img/charts/bar.png\')',
    '--favicon': 'url(\'./assets/img/logo/psb/favicon.ico\')',
    // others
    '--toggle-ps-left':'3px',
    '--toggle-nav-ps-left':'0',
    '--dash-header-bor':'1px solid #e8e8e8',
    '--common-select-option-bg': '#fff',
    '--treemap-td-bor-color': '#fff',
    '--treemap-bg-color': '#4D89A5',
    '--mat-label-bor-bottom':'none',
    '--treemap-height': 'calc(100% - 27px)',
    '--progress-default': '#1784c7',
    '--warning-bg': '#fff9df',
    '--warning-text':'rgba(0, 0, 0, 0.62)',
    '--toggle-btn-fg': '#0083b6',
    '--toggle-btn-bg': 'rgb(116, 188, 233)',
    '--d-icon-menu-color': 'rgba(0,62,100,0.58)',
    '--mat-datepicker-actions-btn-cancel': 'rgba(255, 255, 255, 1)',
    '--mat-datepicker-actions-btn-select-bg': '#fafafa',
    '--dl-file-message-file-size-color': '#858585cc'
  },

  dark_xb : {
    // color
    '--card-dark-light': '#005e89',
    '--card-dark-light-2': '#26252c',
    '--card-dark-light-no-message': 'rgba(38, 37, 44, .8)',
    '--success': '#00c7c7',
    '--primary': '#005e89',
    '--warn': '#ffd475',
    '--danger': '#c86a88',
    '--bg-dark-light':'rgb(45,44,51)',
    '--bg-dark-light-2':'rgb(45,44,51)',
    '--bg-dark-light-3':'rgb(45,44,51)',
    '--color':'#fff',
    '--color-header':'#fff',
    // --color-50: '#aeaeae'
    '--color-50': '#e7e7e7',
    '--orange-gray-50':'#C8A46A',
    '--light-gray':'rgb(45,44,51)',
    '--black-white': '#fff',
    '--black-white-rgba':'rgba(250,250,250,1)',
    '--main-color': '#fff',
    '--menu-color': '#fafafa',
    '--light-gray-white-color': '#fff',
    '--orange-white': '#C8A46A',
    '--orange-dark-blue': '#C8A46A',
    '--orange-black-50': '#C8A46A',
    '--radio-btn-color': '#C8A46A',
    '--orange-blue': '#C8A46A',
    '--white-gold': '#C8A46A',
    '--gray-gold': '#C8A46A',
    '--orange-red': '#C8A46A',
    '--tree-item-active': '#464349',
    // backColor
    '--card-background':'linear-gradient(111.09deg, #4C4951 0%, #353235 100%)',
    '--card-background-dark':'linear-gradient(111.09deg, #4C4951 80%, #353235 100%)',
    '--blue-dark': 'linear-gradient(111.09deg, #4C4951 0%, #353235 100%)',
    '--loader-bg':'#464349',
    '--toast-bg':'rgb(45,44,51)',
    '--system-toast-bg':'rgb(0 0 0 / 24%)',
    '--system-toast-avatar-bg':'rgb(45,44,51)',
    '--form-auth-bg':'linear-gradient(107.28deg, #4C4951 0%, #353235 100%)',
    '--form-auth-header':'linear-gradient(107.28deg, #4C4951 0%, #353235 100%)',
    '--btn-bg-color': 'linear-gradient(124.7deg, #4C4951 0%, #353235 100%)',
    '--background':'#1C1B20',
    '--background-color': '#26252C',
    '--dash-bg':'none',
    '--gridster-bg':'#444',
    '--gridster-dash-bg':'none',
    '--common-select-option-bg': 'rgb(130,130,130)',
    '--charts-types-select-bg': '#30303D',
    '--scrollbar-track-bg': 'rgba(255, 255, 255, .1)',
    '--scrollbar-track-main-bg': 'rgba(255, 255, 255, .4)',
    '--tree-bg': 'rgba(255, 255, 255, .4)',
    '--scrollbar-thumb-main-bg': 'rgba(255, 255, 255, .3)',
    '--scrollbar-thumb-bg': 'rgba(255, 255, 255, .5)',
    '--scrollbar-thumb-bg-select': '#999',
    // hover
    '--hover-color': '#CEBE77',
    '--hover-color-1': '#CEBE77',
    '--row-hover-color': 'rgba(255, 255, 255, .1)',
    '--hover-color-2': 'rgba(206, 190, 119, 0.8)',
    '--hover-color-3': 'rgba(206, 190, 119, 0.2)',
    '--hover-color-dlgram': 'rgba(206, 190, 119, 10%)',
    '--dlgram-active-chat-bg-color': 'rgba(206, 190, 119, 75%)',
    '--dlgram-active-chat-folder-bg': 'rgba(206, 190, 119, 0.15)',
    // button
    '--btn-color': '#fff',
    '--btn-dg-cancel-color': '#fff',
    '--btn-dg-cancel-bg-color': 'red',
    '--btn-dg-cancel-br-color': 'red',
    '--btn-dg-exec-bg-color': '#C8A46A',
    '--btn-dg-exec-br-color': '#C8A46A',
    '--btn-dg-exec-color': '#fff',
    '--card-list-bottom-border': '1px dashed #fff',
    '--btn-disabled-bg-color': 'rgba(150, 150, 150, .1)',
    '--btn-disabled-border-color': 'rgba(255, 255, 255, .5)',
    '--btn-disabled-color': 'rgba(255, 255, 255, .5)',
    //table
    '--table-row-active': '#495367',
    '--th-bg-color': 'rgb(56,56,60)',
    '--grid-focused-row-bg':'rgb(73,83,103)',
    '--th-bor': 'none',
    '--td-bor': 'rgb(63,63,69)',
    // '--td-bor': '1px solid rgb(63,63,69)',
    '--grid-td-border-color': 'rgb(63,63,69)',
    // shadow
    '--bx-shadow': '0 3px 1px -2px rgba(255,255,255,.2), 0 2px 2px 0 rgba(255,255,255,.14), 0 1px 5px 0 rgba(255,255,255,.12)',
    '--dash-bx-shadow': 'rgba(255, 255, 255, 0.3)',
    '--bi-hd-bottom-bor': '2px solid #fff',
    // images
    '--logo-width':'206px',
    '--logo-height':'31px',
    '--logo-file':'url(\'./assets/img/logo/xb/main-logo-dark.svg\')',
    '--logo-file2':'url(\'./assets/img/logo/xb/main-logo-dark.svg\')',
    '--logo-file-2':'url(\'./assets/img/logo/psb/main-logo-dark-2-gray.png\')',
    '--back-img': 'url(\'assets/img/back-dark.svg\')',
    '--auth-title-img': 'url(\'assets/img/logo/xb/logo-text-dark.svg\')',
    '--navbar-logo':'url(\'./assets/img/logo/xb/logo-dark.svg\')',
    '--navbar-logo2':'url(\'./assets/img/logo/xb/logo-dark.svg\')',
    '--navbar-logo-mini':'url(\'./assets/img/logo/xb/logo-mini-dark.svg\')',
    '--theme-sun-icon':'url(\'./assets/img/sun.svg\')',
    '--theme-sun-auth-icon':'url(\'./assets/img/sun.svg\')',
    '--theme-moon-icon':'url(\'./assets/img/moon-dark.svg\')',
    '--pie': 'url(\'./assets/img/charts/pie-dark.png\')',
    '--pie3D': 'url(\'./assets/img/charts/pie3D-dark.png\')',
    '--donut': 'url(\'./assets/img/charts/donut-dark.png\')',
    '--donut3D': 'url(\'./assets/img/charts/donut3D-dark.png\')',
    '--semi-circle': 'url(\'./assets/img/charts/semi-circle-dark.png\')',
    '--radius': 'url(\'./assets/img/charts/radius-dark.png\')',
    '--line': 'url(\'./assets/img/charts/line-dark.png\')',
    '--column': 'url(\'./assets/img/charts/column-dark.png\')',
    '--bar': 'url(\'./assets/img/charts/bar-dark.png\')',
    // others
    '--toggle-ps-left':'-1px',
    '--toggle-nav-ps-left':'-4px',
    '--dash-header-bor':'1px solid #3C3B41',
    '--mat-label-bor-bottom':' 1px solid #3C3B41',
    '--treemap-td-bor-color': '#fff',
    '--treemap-bg-color': 'linear-gradient(111.09deg, #4C4951 0%, #353235 100%)',
    '--treemap-height': 'calc(100% - 27px)',
    '--progress-default': '#C8A46A',
    '--warning-bg': '#fff9df',
    '--warning-text':'rgba(0, 0, 0, 0.62)',
    '--toggle-btn-fg': '#C8A46A',
    '--toggle-btn-bg': '#d1b17d',
    '--d-icon-menu-color': 'rgba(206, 190, 119, 0.8)',
    '--mat-datepicker-actions-btn-cancel': 'rgba(255, 255, 255, .8)',
    '--mat-datepicker-actions-btn-select-bg': 'rgba(150, 150, 150, 0.1)',
    '--dl-file-message-file-size-color': '#858585'
  },

  light_xb: {
    // color
    '--card-dark-light': '#fff',
    '--card-dark-light-2': '#fff',
    '--card-dark-light-no-message': 'rgba(255, 255, 255, .6)',
    '--success': '#00c7c7',
    '--primary': '#005e89',
    '--warn': '#ffd5c0',
    '--danger': '#fc9a5c',
    '--color':'rgb(112,112,112)',
    '--color-header':'#C1964B',
    '--bg-dark-light':'#d1d1d1',
    '--bg-dark-light-2':'rgb(229 235 239)',
    '--bg-dark-light-3':'rgb(255 255 255)',
    '--color-50': 'rgb(133,133,133)',
    '--orange-gray-50':'rgb(112,112,112)',
    '--black-white':'#222',
    '--black-white-rgba':'rgba(0,0,0,1)',
    '--light-gray':'#f3f3f3',
    '--main-color': '#005e89',
    '--menu-color': '#b2b2b2',
    '--light-gray-white-color': '#CACACA',
    '--orange-white': '#fff',
    '--orange-dark-blue': '#C1964B',
    '--orange-black-50': '#555555',
    '--radio-btn-color': '#E91E63',
    '--orange-blue': '#C1964B',
    '--orange-red': 'red',
    '--white-gold': '#fff',
    '--gray-gold': '#cac6c6',
    '--tree-item-active': 'rgb(45,44,51)',
    // backColor
    '--card-background':'#fafafa',
    '--card-background-dark':'#fafafa',
    '--blue-dark': '#0e7bbf',
    '--toast-bg':'rgba(0, 62, 100, 16%)',
    '--system-toast-bg':'rgba(45,44,51, 0.24)',
    '--system-toast-avatar-bg':'rgb(0 0 0 / 24%)',
    '--loader-bg':'#fafafa',
    '--form-auth-bg':'#18645B',
    '--form-auth-header':'#C1964B',
    '--background':'#07584F',
    '--background-color':'#FBFBFB',
    '--dash-bg':'#fff',
    '--gridster-bg':'#61A9CF',
    '--gridster-dash-bg':'#fff',
    '--charts-types-select-bg': '#fff',
    '--scrollbar-track-bg': 'rgba(150, 150, 150, .1)',
    '--scrollbar-track-main-bg': 'rgba(100, 100, 100, .2)',
    '--tree-bg': 'rgba(255, 255, 255, .9)',
    '--scrollbar-thumb-main-bg': 'rgba(150, 150, 150, .3)',
    '--scrollbar-thumb-bg': 'rgba(200, 200, 200, .5)',
    '--scrollbar-thumb-bg-select': 'rgba(200, 200, 200, .5)',
    // hover
    '--hover-color':'#005e89',
    '--hover-color-1':'rgba(0,62,100,0.58)',
    '--row-hover-color': 'rgba(150, 150, 150, .1)',
    '--hover-color-2': 'rgba(0, 62, 100, 0.2)',
    '--hover-color-3': 'rgba(0, 62, 100, 0.2)',
    '--hover-color-dlgram': 'rgba(0, 62, 100, 16%)',
    '--dlgram-active-chat-bg-color': 'rgba(0, 62, 100, 75%)',
    '--dlgram-active-chat-folder-bg': 'rgba(0, 62, 100, 0.15)',
    // button
    '--btn-bg-color':'#d1d1d1',
    '--btn-color':'#fff',
    '--btn-dg-cancel-color':'red',
    '--btn-dg-cancel-br-color':'red',
    '--btn-dg-cancel-bg-color':'#fff',
    '--btn-dg-exec-bg-color':'#fff',
    '--btn-dg-exec-color':'#005e89',
    '--btn-dg-exec-br-color':'#005e89',
    '--logo-width':'206px',
    '--logo-height':'31px',
    '--logo-file':'url(\'./assets/img/logo/xb/main-logo.svg\')',
    '--logo-file2':'url(\'./assets/img/logo/xb/main-logo.svg\')',
    '--back-img':'url(\'assets/img/back.svg\')',
    '--auth-title-img': 'url(\'assets/img/logo/xb/logo-text.svg\')',
    '--card-list-bottom-border':'1px dashed #d1d1d1',
    '--btn-disabled-bg-color': 'rgba(255, 255, 255, .4)',
    '--btn-disabled-border-color': '#00000042',
    '--btn-disabled-color': '#00000042',
    // table
    '--table-row-active': '#d1d1d1',
    '--th-bg-color':'#e8e8e8',
    '--grid-focused-row-bg':'#d1d1d1',
    '--th-bor':'none',
    '--td-bor':'rgb(222,226,230)',
    '--grid-td-border-color': 'rgba(0,0,0,.12)',
    '--bi-hd-bottom-bor':'2px solid red',
    // box shadow
    '--bx-shadow':'0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12)',
    '--dash-bx-shadow': 'rgba(0, 0, 0, 0.3)',
    // images
    '--navbar-logo':'url(\'./assets/img/logo/xb/logo.svg\')',
    '--navbar-logo2':'url(\'./assets/img/logo/xb/logo-dark.svg\')',
    '--navbar-logo-mini':'url(\'./assets/img/logo/xb/logo-mini.svg\')',
    '--theme-sun-icon':'url(\'./assets/img/sun-day.svg\')',
    '--theme-sun-auth-icon':'url(\'./assets/img/sun.svg\')',
    '--theme-moon-icon':'url(\'./assets/img/moon-nav.svg\')',
    '--pie': 'url(\'./assets/img/charts/pie.png\')',
    '--pie3D': 'url(\'./assets/img/charts/pie3D.png\')',
    '--donut': 'url(\'./assets/img/charts/donut.png\')',
    '--donut3D': 'url(\'./assets/img/charts/donut3D.png\')',
    '--semi-circle': 'url(\'./assets/img/charts/semi-circle.png\')',
    '--radius': 'url(\'./assets/img/charts/radius.png\')',
    '--line': 'url(\'./assets/img/charts/line.png\')',
    '--column': 'url(\'./assets/img/charts/column.png\')',
    '--bar': 'url(\'./assets/img/charts/bar.png\')',
    '--logo-file-2':'url(\'./assets/img/logo/psb/main-logo-light-2.svg\')',
    // others
    '--toggle-ps-left':'3px',
    '--toggle-nav-ps-left':'0',
    '--dash-header-bor':'1px solid #e8e8e8',
    '--common-select-option-bg': '#fff',
    '--treemap-td-bor-color': '#fff',
    '--treemap-bg-color': '#4D89A5',
    '--mat-label-bor-bottom':'none',
    '--treemap-height': 'calc(100% - 27px)',
    '--progress-default': '#1784c7',
    '--warning-bg': '#fff9df',
    '--warning-text':'rgba(0, 0, 0, 0.62)',
    '--toggle-btn-fg': '#0083b6',
    '--toggle-btn-bg': 'rgb(116, 188, 233)',
    '--d-icon-menu-color': 'rgba(0,62,100,0.58)',
    '--mat-datepicker-actions-btn-cancel': 'rgba(255, 255, 255, 1)',
    '--mat-datepicker-actions-btn-select-bg': '#fafafa',
    '--dl-file-message-file-size-color': '#858585cc'
  },

  dark_trust : {
    // color
    '--card-dark-light': '#005e89',
    '--card-dark-light-2': '#26252c',
    '--card-dark-light-no-message': 'rgba(38, 37, 44, .8)',
    '--success': '#00c7c7',
    '--primary': '#005e89',
    '--warn': '#ffd475',
    '--danger': '#c86a88',
    '--bg-dark-light':'rgb(45,44,51)',
    '--bg-dark-light-2':'rgb(45,44,51)',
    '--bg-dark-light-3':'rgb(45,44,51)',
    '--color':'#fff',
    '--color-header':'#fff',
    // --color-50: '#aeaeae'
    '--color-50': '#e7e7e7',
    '--orange-gray-50':'#C8A46A',
    '--light-gray':'rgb(45,44,51)',
    '--black-white': '#fff',
    '--black-white-rgba':'rgba(250,250,250,1)',
    '--main-color': '#fff',
    '--menu-color': '#fafafa',
    '--light-gray-white-color': '#fff',
    '--orange-white': '#C8A46A',
    '--orange-dark-blue': '#C8A46A',
    '--orange-black-50': '#C8A46A',
    '--radio-btn-color': '#C8A46A',
    '--orange-blue': '#C8A46A',
    '--white-gold': '#C8A46A',
    '--gray-gold': '#C8A46A',
    '--orange-red': '#C8A46A',
    '--tree-item-active': '#464349',
    // backColor
    '--card-background':'linear-gradient(111.09deg, #4C4951 0%, #353235 100%)',
    '--card-background-dark':'linear-gradient(111.09deg, #4C4951 80%, #353235 100%)',
    '--blue-dark': 'linear-gradient(111.09deg, #4C4951 0%, #353235 100%)',
    '--loader-bg':'#464349',
    '--toast-bg':'rgb(45,44,51)',
    '--system-toast-bg':'rgb(0 0 0 / 24%)',
    '--system-toast-avatar-bg':'rgb(45,44,51)',
    '--form-auth-bg':'linear-gradient(107.28deg, #4C4951 0%, #353235 100%)',
    '--form-auth-header':'linear-gradient(107.28deg, #4C4951 0%, #353235 100%)',
    '--btn-bg-color': 'linear-gradient(124.7deg, #4C4951 0%, #353235 100%)',
    '--background':'#1C1B20',
    '--background-color': '#26252C',
    '--dash-bg':'none',
    '--gridster-bg':'#444',
    '--gridster-dash-bg':'none',
    '--common-select-option-bg': 'rgb(130,130,130)',
    '--charts-types-select-bg': '#30303D',
    '--scrollbar-track-bg': 'rgba(255, 255, 255, .1)',
    '--scrollbar-track-main-bg': 'rgba(255, 255, 255, .4)',
    '--tree-bg': 'rgba(255, 255, 255, .4)',
    '--scrollbar-thumb-main-bg': 'rgba(255, 255, 255, .3)',
    '--scrollbar-thumb-bg': 'rgba(255, 255, 255, .5)',
    '--scrollbar-thumb-bg-select': '#999',
    // hover
    '--hover-color': '#CEBE77',
    '--hover-color-1': '#CEBE77',
    '--row-hover-color': 'rgba(255, 255, 255, .1)',
    '--hover-color-2': 'rgba(206, 190, 119, 0.8)',
    '--hover-color-3': 'rgba(206, 190, 119, 0.2)',
    '--hover-color-dlgram': 'rgba(206, 190, 119, 10%)',
    '--dlgram-active-chat-bg-color': 'rgba(206, 190, 119, 75%)',
    '--dlgram-active-chat-folder-bg': 'rgba(206, 190, 119, 0.15)',
    // button
    '--btn-color': '#fff',
    '--btn-dg-cancel-color': '#fff',
    '--btn-dg-cancel-bg-color': 'red',
    '--btn-dg-cancel-br-color': 'red',
    '--btn-dg-exec-bg-color': '#C8A46A',
    '--btn-dg-exec-br-color': '#C8A46A',
    '--btn-dg-exec-color': '#fff',
    '--card-list-bottom-border': '1px dashed #fff',
    '--btn-disabled-bg-color': 'rgba(150, 150, 150, .1)',
    '--btn-disabled-border-color': 'rgba(255, 255, 255, .5)',
    '--btn-disabled-color': 'rgba(255, 255, 255, .5)',
    //table
    '--table-row-active': '#495367',
    '--th-bg-color': 'rgb(56,56,60)',
    '--grid-focused-row-bg':'rgb(73,83,103)',
    '--th-bor': 'none',
    '--td-bor': 'rgb(63,63,69)',
    // '--td-bor': '1px solid rgb(63,63,69)',
    '--grid-td-border-color': 'rgb(63,63,69)',
    // shadow
    '--bx-shadow': '0 3px 1px -2px rgba(255,255,255,.2), 0 2px 2px 0 rgba(255,255,255,.14), 0 1px 5px 0 rgba(255,255,255,.12)',
    '--dash-bx-shadow': 'rgba(255, 255, 255, 0.3)',
    '--bi-hd-bottom-bor': '2px solid #fff',
    // images
    '--logo-width':'100px',
    '--logo-height':'44px',
    '--logo-file':'url(\'./assets/img/logo/trust/main-logo-dark.svg\')',
    '--logo-file2':'url(\'./assets/img/logo/trust/main-logo-dark.svg\')',
    '--logo-file-2':'url(\'./assets/img/logo/psb/main-logo-dark-2-gray.png\')',
    '--back-img': 'url(\'assets/img/back-dark.svg\')',
    '--auth-title-img': 'url(\'assets/img/logo/trust/logo-text-dark.svg\')',
    '--navbar-logo':'url(\'./assets/img/logo/trust/logo-dark.svg\')',
    '--navbar-logo2':'url(\'./assets/img/logo/trust/logo-dark.svg\')',
    '--navbar-logo-mini':'url(\'./assets/img/logo/trust/sqb-mini-dark.png\')',
    '--theme-sun-icon':'url(\'./assets/img/sun.svg\')',
    '--theme-sun-auth-icon':'url(\'./assets/img/sun.svg\')',
    '--theme-moon-icon':'url(\'./assets/img/moon-dark.svg\')',
    '--pie': 'url(\'./assets/img/charts/pie-dark.png\')',
    '--pie3D': 'url(\'./assets/img/charts/pie3D-dark.png\')',
    '--donut': 'url(\'./assets/img/charts/donut-dark.png\')',
    '--donut3D': 'url(\'./assets/img/charts/donut3D-dark.png\')',
    '--semi-circle': 'url(\'./assets/img/charts/semi-circle-dark.png\')',
    '--radius': 'url(\'./assets/img/charts/radius-dark.png\')',
    '--line': 'url(\'./assets/img/charts/line-dark.png\')',
    '--column': 'url(\'./assets/img/charts/column-dark.png\')',
    '--bar': 'url(\'./assets/img/charts/bar-dark.png\')',
    // others
    '--toggle-ps-left':'-1px',
    '--toggle-nav-ps-left':'-4px',
    '--dash-header-bor':'1px solid #3C3B41',
    '--mat-label-bor-bottom':' 1px solid #3C3B41',
    '--treemap-td-bor-color': '#fff',
    '--treemap-bg-color': 'linear-gradient(111.09deg, #4C4951 0%, #353235 100%)',
    '--treemap-height': 'calc(100% - 27px)',
    '--progress-default': '#C8A46A',
    '--warning-bg': '#fff9df',
    '--warning-text':'rgba(0, 0, 0, 0.62)',
    '--toggle-btn-fg': '#C8A46A',
    '--toggle-btn-bg': '#d1b17d',
    '--d-icon-menu-color': 'rgba(206, 190, 119, 0.8)',
    '--mat-datepicker-actions-btn-cancel': 'rgba(255, 255, 255, .8)',
    '--mat-datepicker-actions-btn-select-bg': 'rgba(150, 150, 150, 0.1)',
    '--dl-file-message-file-size-color': '#858585'
  },

  light_trust: {
    // color
    '--card-dark-light': '#fff',
    '--card-dark-light-2': '#fff',
    '--card-dark-light-no-message': 'rgba(255, 255, 255, .6)',
    '--success': '#00c7c7',
    '--primary': '#005e89',
    '--warn': '#ffd5c0',
    '--danger': '#fc9a5c',
    '--bg-dark-light':'#d1d1d1',
    '--bg-dark-light-2':'rgb(229 235 239)',
    '--bg-dark-light-3':'rgb(255 255 255)',
    '--color':'rgb(112,112,112)',
    '--color-header':'rgb(112,112,112)',
    '--color-50': 'rgb(133,133,133)',
    '--orange-gray-50':'rgb(112,112,112)',
    '--black-white':'#222',
    '--black-white-rgba':'rgba(0,0,0,1)',
    '--light-gray':'#f3f3f3',
    '--main-color': '#005e89',
    '--menu-color': '#b2b2b2',
    '--light-gray-white-color': '#CACACA',
    '--orange-white': '#fff',
    '--orange-dark-blue': '#005e89',
    '--orange-black-50': '#555555',
    '--radio-btn-color': '#E91E63',
    '--orange-blue': '#3F51B5',
    '--orange-red': 'red',
    '--white-gold': '#fff',
    '--gray-gold': '#cac6c6',
    '--tree-item-active': 'rgb(45,44,51)',
    // backColor
    '--card-background':'#fafafa',
    '--card-background-dark':'#fafafa',
    '--blue-dark': '#0e7bbf',
    '--toast-bg':'rgba(0, 62, 100, 16%)',
    '--system-toast-bg':'rgba(45,44,51, 0.24)',
    '--system-toast-avatar-bg':'rgb(0 0 0 / 24%)',
    '--loader-bg':'#fafafa',
    '--form-auth-bg':'linear-gradient(312.16deg, #005e89 8.63%, #001827 97.1%)',
    '--form-auth-header':'linear-gradient(312.16deg, #005e89 8.63%, #001827 97.1%)',
    '--background':'#fafafa',
    '--background-color':'#FBFBFB',
    '--dash-bg':'#fff',
    '--gridster-bg':'#61A9CF',
    '--gridster-dash-bg':'#fff',
    '--charts-types-select-bg': '#fff',
    '--scrollbar-track-bg': 'rgba(150, 150, 150, .1)',
    '--scrollbar-track-main-bg': 'rgba(100, 100, 100, .2)',
    '--tree-bg': 'rgba(255, 255, 255, .9)',
    '--scrollbar-thumb-main-bg': 'rgba(150, 150, 150, .3)',
    '--scrollbar-thumb-bg': 'rgba(200, 200, 200, .5)',
    '--scrollbar-thumb-bg-select': 'rgba(200, 200, 200, .5)',
    // hover
    '--hover-color':'#005e89',
    '--hover-color-1':'rgba(0,62,100,0.58)',
    '--row-hover-color': 'rgba(150, 150, 150, .1)',
    '--hover-color-2': 'rgba(0, 62, 100, 0.2)',
    '--hover-color-3': 'rgba(0, 62, 100, 0.2)',
    '--hover-color-dlgram': 'rgba(0, 62, 100, 16%)',
    '--dlgram-active-chat-bg-color': 'rgba(0, 62, 100, 75%)',
    '--dlgram-active-chat-folder-bg': 'rgba(0, 62, 100, 0.15)',
    // button
    '--btn-bg-color':'#d1d1d1',
    '--btn-color':'#fff',
    '--btn-dg-cancel-color':'red',
    '--btn-dg-cancel-br-color':'red',
    '--btn-dg-cancel-bg-color':'#fff',
    '--btn-dg-exec-bg-color':'#fff',
    '--btn-dg-exec-color':'#005e89',
    '--btn-dg-exec-br-color':'#005e89',
    '--logo-width':'100px',
    '--logo-height':'44px',
    '--logo-file':'url(\'./assets/img/logo/trust/main-logo.svg\')',
    '--logo-file2':'url(\'./assets/img/logo/trust/main-logo.svg\')',
    '--back-img':'url(\'assets/img/back.svg\')',
    '--auth-title-img': 'url(\'assets/img/logo/trust/logo-text.svg\')',
    '--card-list-bottom-border':'1px dashed #d1d1d1',
    '--btn-disabled-bg-color': 'rgba(255, 255, 255, .4)',
    '--btn-disabled-border-color': '#00000042',
    '--btn-disabled-color': '#00000042',
    // table
    '--table-row-active': '#d1d1d1',
    '--th-bg-color':'#e8e8e8',
    '--grid-focused-row-bg':'#d1d1d1',
    '--th-bor':'none',
    '--td-bor':'rgb(222,226,230)',
    '--grid-td-border-color': 'rgba(0,0,0,.12)',
    '--bi-hd-bottom-bor':'2px solid red',
    // box shadow
    '--bx-shadow':'0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12)',
    '--dash-bx-shadow': 'rgba(0, 0, 0, 0.3)',
    // images
    '--navbar-logo':'url(\'./assets/img/logo/trust/logo.svg\')',
    '--navbar-logo2':'url(\'./assets/img/logo/trust/logo-dark.svg\')',
    '--navbar-logo-mini':'url(\'./assets/img/logo/trust/sqb-mini.png\')',
    '--theme-sun-icon':'url(\'./assets/img/sun-day.svg\')',
    '--theme-sun-auth-icon':'url(\'./assets/img/sun.svg\')',
    '--theme-moon-icon':'url(\'./assets/img/moon-nav.svg\')',
    '--pie': 'url(\'./assets/img/charts/pie.png\')',
    '--pie3D': 'url(\'./assets/img/charts/pie3D.png\')',
    '--donut': 'url(\'./assets/img/charts/donut.png\')',
    '--donut3D': 'url(\'./assets/img/charts/donut3D.png\')',
    '--semi-circle': 'url(\'./assets/img/charts/semi-circle.png\')',
    '--radius': 'url(\'./assets/img/charts/radius.png\')',
    '--line': 'url(\'./assets/img/charts/line.png\')',
    '--column': 'url(\'./assets/img/charts/column.png\')',
    '--bar': 'url(\'./assets/img/charts/bar.png\')',
    '--logo-file-2':'url(\'./assets/img/logo/psb/main-logo-light-2.svg\')',
    // others
    '--toggle-ps-left':'3px',
    '--toggle-nav-ps-left':'0',
    '--dash-header-bor':'1px solid #e8e8e8',
    '--common-select-option-bg': '#fff',
    '--treemap-td-bor-color': '#fff',
    '--treemap-bg-color': '#4D89A5',
    '--mat-label-bor-bottom':'none',
    '--treemap-height': 'calc(100% - 27px)',
    '--progress-default': '#1784c7',
    '--warning-bg': '#fff9df',
    '--warning-text':'rgba(0, 0, 0, 0.62)',
    '--toggle-btn-fg': '#0083b6',
    '--toggle-btn-bg': 'rgb(116, 188, 233)',
    '--d-icon-menu-color': 'rgba(0,62,100,0.58)',
    '--mat-datepicker-actions-btn-cancel': 'rgba(255, 255, 255, 1)',
    '--mat-datepicker-actions-btn-select-bg': '#fafafa',
    '--dl-file-message-file-size-color': '#858585cc'
  },
};
