import {HttpClient} from '@angular/common/http';
import {MultiTranslateHttpLoader} from 'ngx-translate-multi-http-loader';
import {POSITION} from 'ngx-ui-loader';
import {environment} from '@env/environment';

export const LoaderProperties = {
  bgsPosition: POSITION.centerCenter,
  bgsSize: 0,
  fgsSize: 0,
  bgsOpacity: 0,
  overlayColor:'var(--loader-bg)',
  logoUrl : environment.spinner.url,
  logoPosition: POSITION.centerCenter,
  threshold : 0,
  logoSize: environment.spinner.size
};


export function HttpLoaderFactory(httpClient: HttpClient) {
  return new MultiTranslateHttpLoader(httpClient, [
    {prefix: "./assets/locale/acc/", suffix: ".json"},
    {prefix: "./assets/locale/acc/am/", suffix: ".json"},
    {prefix: "./assets/locale/acc/tm/", suffix: ".json"},
    {prefix: "./assets/locale/acc/hr/", suffix: ".json"},
    {prefix: "./assets/locale/acc/ic/", suffix: ".json"},
    {prefix: "./assets/locale/acc/mp/", suffix: ".json"},
    {prefix: "./assets/locale/acc/sl/", suffix: ".json"},
    {prefix: "./assets/locale/acc/ca/", suffix: ".json"},
    {prefix: "./assets/locale/acc/ap/", suffix: ".json"},
    {prefix: "./assets/locale/acc/fa/", suffix: ".json"},
    {prefix: "./assets/locale/acc/te/", suffix: ".json"},
    {prefix: "./assets/locale/acc/dv/", suffix: ".json"},
    {prefix: "./assets/locale/bi/", suffix: ".json"},
    {prefix: "./assets/locale/psn/", suffix: ".json"},
    {prefix: "./assets/locale/pfm/", suffix: ".json"},
    {prefix: "./assets/locale/bud/", suffix: ".json"},
    {prefix: "./assets/locale/cb/", suffix: ".json"},
    {prefix: "./assets/locale/ch/", suffix: ".json"},
    {prefix: "./assets/locale/sp/", suffix: ".json"},
    {prefix: "./assets/locale/cr/", suffix: ".json"},
    {prefix: "./assets/locale/cd/", suffix: ".json"},
    {prefix: "./assets/locale/core/", suffix: ".json"},
    {prefix: "./assets/locale/auc/", suffix: ".json"},
    {prefix: "./assets/locale/dle/", suffix: ".json"},
    {prefix: "./assets/locale/dlgram/", suffix: ".json"},
    {prefix: "./assets/locale/gsb/", suffix: ".json"},
    {prefix: "./assets/locale/ics/", suffix: ".json"},
    {prefix: "./assets/locale/lc/", suffix: ".json"},
    {prefix: "./assets/locale/mid/", suffix: ".json"},
    {prefix: "./assets/locale/res/", suffix: ".json"},
    {prefix: "./assets/locale/sac/", suffix: ".json"},
    {prefix: "./assets/locale/fo/", suffix: ".json"},
    {prefix: "./assets/locale/cn/", suffix: ".json"}
  ]);
}
